import React, { useEffect } from "react";
import Seo from "../components/Seo";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

const title = "Affiliate Program - PlagiaShield";
const description =
  "Get new revenue while helping companies protect their website from plagiarism.";

const StyledH2 = styled.h2`
  font-size: 2.5rem;
  /* font-weight: 800; */
  margin-bottom: 2rem;
  @media only screen and (min-width: 700px) {
    font-size: 3rem;
  }
`;

const IndexPage = () => {
  // const isLarge = useMediaQuery("(min-width: 48rem)");

  useEffect(() => {
    if (window.civchat) {
      window.userengage("event.affiliate_visited");
    }
  }, []);

  const ProgramDetails = () => (
    <Row className="program-details">
      <div className="col-md-8">
        {/* <h2>40% recurring commissions</h2> */}
        <p style={{ fontSize: "1.5rem" }}>Get rewarded for the sales you generate.</p>
        <p style={{ fontSize: "1.5rem" }}>
          Get special support from the team: webinar, guest post, email copy, special coupons.
        </p>
        <p style={{ fontSize: "1.5rem" }}>Help your audience keep its content unique.</p>
      </div>
      {/* <div className="col-md-4 text-center">
        <p>
          <span style={{ fontSize: "3rem" }}>40%</span>
          <br />
          revenue share
          <br />
          within the first year
        </p>
      </div> */}
    </Row>
  );

  const ClickAffiliateButton = () => {
    if (window.civchat) {
      window.userengage("event.affiliate_button_clicked");
    }
  };

  return (
    <React.Fragment>
      <div className="plagia-checker-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 className="display-4">Affiliate Program</h1>
        <p className="lead mb-4">
          Get new revenue while helping companies protect their website from plagiarism.
        </p>
        <div className="text-center ">
          <Button
            // href="https://plagiashield.getrewardful.com/signup"
            // rel="noopener noreferrer nofollow"
            // target="_blank"
            size="lg"
            onClick={ClickAffiliateButton}
          >
            Join the affiliate program
          </Button>
        </div>
      </div>
      <hr />
      <Container className="mt-5">
        <ProgramDetails />
      </Container>
      <Seo manualTitle={title} manualDescription={description} manualSlug="/affiliate-program" />
    </React.Fragment>
  );
};

export default IndexPage;
